export default [
  {
    key: 'increasement',
    sortable: true,
    sortField: 'id',
    label: '#',
    thClass: 'w-50px text-center',
    tdClass: 'text-center',
  },
  {
    key: 'name',
    label: 'field.name',
  },
  {
    key: 'description',
    label: 'field.description',
  },
  {
    key: 'actions',
    label: 'field.action',
    thClass: 'text-center',
    tdClass: 'text-center',
  },
];
